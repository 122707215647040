import shortid from 'shortid';

const HOST = 'https://g0yu0l4pxj.s3.amazonaws.com/';


const mapFile = file => {
    const { id, filetype, progress, status, key } = file;
    const location = HOST + key;
    const name = file.file.name;

    return {
        id,
        progress,
        filetype,
        status,
        location,
        filename: name,
        size: file.file.size,
        ext: /[.]/.exec(name) ? /[^.]+$/.exec(name) : 'unknown',
        reloadFile: {
          file: file.file,
          key,
          id
        }
    };
}

class Uploader {

    constructor({ onAddedFiles, onProgressFiles, onLoadedFiles, onRemovedFiles, onFailedFiles }) {
        this.files = [];
        this.onAddedFiles = onAddedFiles;
        this.onProgressFiles = onProgressFiles;
        this.onLoadedFiles = onLoadedFiles;
        this.onRemovedFiles = onRemovedFiles;
        this.onFailedFiles = onFailedFiles;
        this.onFail = this.onFail.bind(this);
        this.timer = 0;
    }

    onProgress(id, progress) {
        const fileIndex = this.files.findIndex(file => file.id === id)
        this.files.splice(fileIndex, 1, {
            ...this.files[fileIndex],
            progress,
            status: 'loading'
        })
        if (this.onProgressFiles) this.onProgressFiles(this.getFiles())
    }

    onLoad(id) {
        const fileIndex = this.files.findIndex(file => file.id === id)
        this.files.splice(fileIndex, 1, {
            ...this.files[fileIndex],
            status: 'loaded'
        })
        if (this.onLoadedFiles) this.onLoadedFiles(this.getFiles(), id)
    }

    onError(id) {
        const fileIndex = this.files.findIndex(file => file.id === id)
        this.files.splice(fileIndex, 1, {
            ...this.files[fileIndex],
            status: 'error'
        })
        if (this.onLoadedFiles) this.onLoadedFiles(this.getFiles(), id)
    }

    removeFile(id) {
        const fileIndex = this.files.findIndex(file => file.id === id);
        if (this.files[fileIndex] && this.files[fileIndex].xhr) {
            this.files[fileIndex].xhr.abort();
        }
        const removedFile = this.files.splice(fileIndex, 1)[0];
        if (this.onRemovedFiles) this.onRemovedFiles(this.getFiles(), mapFile(removedFile))
    }

    addFiles(files, filetype) {
        this.files = this.files.concat(
            files.map(
                file => {
                    const id = shortid.generate();
                    const key = `anh18ug2/${id}/${file.name}`;
                    return {
                        id,
                        key,
                        file,
                        filetype,
                        xhr: this.getXHR(id, file, key),
                        status: 'loading',
                        progress: null
                    }
                }
            )
        )
        if (this.onAddedFiles) this.onAddedFiles(this.getFiles());
    }

    onFail = id => {
      let progress = 0;

      return () => {
        const fileIndex = this.files.findIndex(file => file.id === id);
        const result =
          progress > 0 &&
          progress < 100 &&
          progress === this.files[fileIndex] && this.files[fileIndex].progress;
        progress = this.files[fileIndex] && this.files[fileIndex].progress;

        if (!result) {
          return false;
        }

        this.files.splice(fileIndex, 1, {
          ...this.files[fileIndex],
          status: 'failed'
        });

        if (this.onFailedFiles) this.onFailedFiles(this.getFiles());

        this.files[fileIndex].xhr.abort();

        clearInterval(this.timer);
      };
    };

    reloadFile(reloadFile, filetype) {
      const id = reloadFile.id;
      const key = reloadFile.key;

      this.files = this.files.concat([
        {
          id,
          key,
          file: reloadFile.file,
          filetype,
          xhr: this.getXHR(id, reloadFile.file, key),
          status: 'error',
          progress: 0
        }
      ]);
      this.removeFile(id);
      if (this.onAddedFiles) this.onAddedFiles(this.getFiles());
    }


    getFiles() {
        return this.files.map(mapFile);
    }

    setFiles(files) {
        this.files = this.files.concat(files.map(file => ({
            ...file,
            file: { name: file.filename, size: file.size },
        })));
    }

    getXHR(id, file, key) {
        const formData = new FormData();
        formData.append('key', key);
        formData.append('AWSAccessKeyId', 'AKIAQ2ZTVN6TGLVWHB72');
        formData.append('acl', 'public-read');
        formData.append('policy', 'CnsgImV4cGlyYXRpb24iOiAiMjAzMy0xMi0wMVQxMjowMDowMC4wMDBaIiwgCiAiY29uZGl0aW9ucyI6IFsgCiB7ImJ1Y2tldCI6ICJnMHl1MGw0cHhqIiB9LCAKIFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICJhbmgxOHVnMi8iXSwgCiBbInN0YXJ0cy13aXRoIiwgIkNvbnRlbnQtVHlwZSIsICIiXSwgCiB7ImFjbCI6ICJwdWJsaWMtcmVhZCIgfQogIF0KIH0K');
        formData.append('signature', 'cg24HI4PkyFpugStJDZLiCXVwFQ=');
        formData.append('file', file);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', HOST);

        this.timer = setInterval(this.onFail(id), 1000 * 40);

        xhr.upload.onprogress = e => {
            if (e.lengthComputable) {
                const percent = Math.round(100 * e.loaded / e.total)
                this.onProgress(id, percent)
            }
        };

        xhr.upload.onabort = () => {
          clearInterval(this.timer);
        };

        xhr.upload.onload = () => {
          this.onLoad(id);
          clearInterval(this.timer);
        };

        xhr.upload.onerror = () => {
          this.onError(id);
          clearInterval(this.timer);
        };

        xhr.send(formData);
        return xhr;
    }
}

export default Uploader
